import React from 'react'
import * as styles from '../../assets/styles.module.scss'

export default () => (
    <section className={styles.Services}>
        <section className={styles.Centered}>
            <h1>Our customers hire us, among other things, to</h1>

            <div className={styles.Block}>
                <h2>Design, build, and launch digital products and services</h2>

                <p>
                    with the help of a truly awesome experienced team.
                    {/* and <a href="/resources/white-papers/product-design-process-guide">
                         experienced team.
                    </a> */}
                </p>
            </div>

            <div className={styles.Block}>
                <h2>Solve business problems with a unique and creative approach</h2>

                <p>by deep-diving into the business processes to design a more efficient way to do it.</p>
            </div>

            <div className={styles.Block}>
                <h2>Explore opportunities and new markets to conquer</h2>

                <p>by getting out of the building and creating value by solving the right problems.</p>
            </div>

            <div className={styles.Block}>
                <h2>Plan and execute a strategy to increase conversion rates</h2>

                <p>by collecting, analyzing and understanding market needs, goals and desires.</p>
            </div>

            <div className={styles.Block}>
                <h2>Extend internal teams with creativity and expertise</h2>

                <p>by adding a skilled and experienced team to boost their innovative ideas.</p>
            </div>

            <div className={styles.Block}>
                <h2>Modernize legacy systems</h2>

                <p>not only by just re-writing the code base but by designing a new process before building on top of the current one.</p>
            </div>
        </section>
    </section>
)